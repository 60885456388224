@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #227aff;
  /* primary: "#227AFF",
      light: "#EFF5FF",
      accent: "#A06D3A", */
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Outfit", sans-serif !important;
  width: 100%;
  overflow-x: hidden;
}
* {
  outline: none !important;
  font-family: "Outfit", sans-serif !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
center {
  @apply flex items-center justify-center;
}
.animate {
  @apply transform transition duration-300 ease-in-out;
}
button {
  @apply py-1 !important;
}
main {
  @apply container mx-auto px-4 lg:px-8 py-4 overflow-x-hidden;
}

.bg-1 {
  background-image: url("./assets/bg1.png");
  background-repeat: no-repeat;
  @apply bg-primary;
  background-position: center;
  background-size: cover;
}
/* #TYPO */
h1 {
  @apply text-4xl lg:text-5xl font-medium;
}
h2 {
  @apply text-3xl font-semibold;
}
h4 {
  @apply text-xl font-semibold;
}
h6 {
  @apply text-base font-semibold;
}
p {
  @apply text-base font-normal text-gray-600;
}

input {
  @apply bg-white  placeholder:text-gray-300 !important;
}
.ff-email-widget {
  @apply bg-gray-100 !important;
}
.ff-powered-img img {
  @apply hidden !important;
}
.switch-check input {
  background-color: #227aff !important;
}
.switch-uncheck input {
  background-color: #7c7c7c !important;
}

/* Internal Player */

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block;
  width: 50px !important;
  height: 50px;
}

.plyr__volume input[type=range] {
  background-color: transparent !important;
}

.plyr__progress input[type=range] {
  background-color: transparent !important;
}

/* Mobile Apps */

.attend-mobile-app {
  @apply relative flex items-center justify-center gap-x-2 md:gap-x-4 mb-3
}

.attend-mobile-app a {
  @apply flex items-center justify-center gap-x-2 md:gap-x-4
}

.attend-mobile-app a img {
  @apply h-10 md:h-12
}

/* Form Inputs */

.peer:disabled ~ .peer-disabled\:text-transparent {
  color: #1e0c0c;
}

.peer:disabled ~ .peer-disabled\:before\:border-transparent::before {
  content: var(--tw-content); 
  border-color: #E5E7EB !important;
}


.peer:disabled ~ .peer-disabled\:after\:border-transparent::after {
   content: var(--tw-content); 
   border-color: #E5E7EB !important; 
}

.disabled\:border-0:disabled {
  border-width: 1px !important;
}

.peer:disabled ~ .peer-disabled\:text-transparent {
  color: #1e0c0c !important;
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:before\:border-transparent::before {
  border-color: #E5E7EB !important; 
}

.peer:placeholder-shown
  ~ .peer-placeholder-shown\:after\:border-transparent::after {
  border-color: #e5e7eb !important;
}

/* Contact Us */

.social-media-item {
  @apply relative;
}

.social-media-item a img {
  @apply w-6 md:w-8;
}

.profile-tabs {
  @apply relative px-0 sm:px-2 md:px-10 py-3  md:py-6 lg:py-8 mx-auto mb-4;
}

.profile-tabs ul li a {
  @apply inline-flex items-center gap-x-2 bg-[#F7F7F7] text-[#6B6B6B] py-2 px-4 rounded-lg font-semibold text-sm md:text-base;
}

.profile-tabs ul li a.active {
  @apply text-primary bg-[#F5F8FE];
}

/* New Home */

.context {
  @apply relative px-4 md:px-6 lg:px-10 xl:px-40 mx-auto;
}

.location-icon {
  @apply inline-flex justify-center items-center rounded-full mr-2 bg-primary p-1.5
}

.location-icon path {
  @apply stroke-white
}